
import { defineComponent, provide } from 'vue'
import { useRouter } from 'vue-router'

import Header from '@/components/globals/Header.vue'
import Footer from '@/components/globals/Footer.vue'

import store from '@/store/app'

export default defineComponent({
  name: 'App',
  components: { Header, Footer },
  setup() {
    const router = useRouter()
    provide(store.key, store)

    store.setup(router)

    return { store }
  },
})
