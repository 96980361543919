
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { Auth } from 'aws-amplify'

export default defineComponent({
  name: 'Header',
  props: {
    signOut: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()

    const onSignOut = async () => {
      await Auth.signOut()
      router.replace({ name: 'Login' })
    }

    const goHome = () => {
      router.replace({ name: 'Home' })
    }

    return {
      goHome,
      onSignOut,
    }
  },
})
