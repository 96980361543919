import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/satellite-site',
    name: 'SatelliteSite',
    component: () =>
      import(
        /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite.vue'
      ),
    children: [
      {
        path: '',
        name: 'SatelliteSiteIndex',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Index.vue'
          ),
      },

      {
        path: 'domain',
        name: 'SatelliteSiteDomainIndex',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Domain/Index.vue'
          ),
        children: [
          {
            path: 'register',
            name: 'SatelliteSiteDomainRegister',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Domain/Register.vue'
              ),
          },
          {
            path: 'pending',
            name: 'SatelliteSiteDomainPending',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Domain/Pending.vue'
              ),
          },
        ],
      },

      {
        path: 'create',
        name: 'SatelliteSiteCreate',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Create.vue'
          ),
      },
      {
        path: 'update',
        name: 'SatelliteSiteUpdate',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Update.vue'
          ),
      },
      {
        path: 'update/contents',
        name: 'SatelliteSiteUpdateContents',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/UpdateContents.vue'
          ),
      },
      {
        path: 'delete',
        name: 'SatelliteSiteDelete',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Delete.vue'
          ),
      },
      {
        path: 'move',
        name: 'SatelliteSiteMove',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Move.vue'
          ),
      },
      {
        path: 'batch',
        name: 'SatelliteSiteBatch',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'SatelliteSiteBatchConsole',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Console.vue'
              ),
          },

          {
            path: 'register/domain',
            name: 'SatelliteSiteBatchRegisterDomainList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Register/Domain/List.vue'
              ),
          },
          {
            path: 'register/domain/:id',
            name: 'SatelliteSiteBatchRegisterDomainDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Register/Domain/Detail.vue'
              ),
          },

          {
            path: 'build/site',
            name: 'SatelliteSiteBatchBuildSiteList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Build/Site/List.vue'
              ),
          },
          {
            path: 'build/site/:id',
            name: 'SatelliteSiteBatchBuildSiteDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Build/Site/Detail.vue'
              ),
          },

          {
            path: 'move/site',
            name: 'SatelliteSiteBatchMoveSiteList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Move/Site/List.vue'
              ),
          },
          {
            path: 'move/site/:id',
            name: 'SatelliteSiteBatchMoveSiteDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Move/Site/Detail.vue'
              ),
          },

          {
            path: 'delete/site',
            name: 'SatelliteSiteBatchDeleteSiteList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Delete/Site/List.vue'
              ),
          },
          {
            path: 'delete/site/:id',
            name: 'SatelliteSiteBatchDeleteSiteDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Delete/Site/Detail.vue'
              ),
          },

          {
            path: 'update/site',
            name: 'SatelliteSiteBatchUpdateSiteList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Update/Site/List.vue'
              ),
          },
          {
            path: 'update/site/:id',
            name: 'SatelliteSiteBatchUpdateSiteDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Update/Site/Detail.vue'
              ),
          },

          {
            path: 'import/domain',
            name: 'SatelliteSiteBatchImportDomainList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Import/Domain/List.vue'
              ),
          },
          {
            path: 'import/domain/:id',
            name: 'SatelliteSiteBatchImportDomainDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Import/Domain/Detail.vue'
              ),
          },
          {
            path: 'import/site',
            name: 'SatelliteSiteBatchImportSiteList',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Import/Site/List.vue'
              ),
          },
          {
            path: 'import/site/:id',
            name: 'SatelliteSiteBatchImportSiteDetail',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Batch/Import/Site/Detail.vue'
              ),
          },
        ],
      },
      {
        path: 'import',
        name: 'SatelliteSiteImportIndex',
        component: () =>
          import(
            /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Import/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'SatelliteSiteImportConsole',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Import/Console.vue'
              ),
          },
          {
            path: 'upload',
            name: 'SatelliteSiteImportUpload',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Import/Upload.vue'
              ),
          },
          {
            path: 'upload2',
            name: 'SatelliteSiteImportUpload2',
            component: () =>
              import(
                /* webpackChunkName: "satellite-site" */ '../views/SatelliteSite/Import/Upload2.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'AdminIndex',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Admin/Index.vue'),
      },
      {
        path: 'users',
        name: 'AdminUsersIndex',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/Admin/Users/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'AdminUsersList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../views/Admin/Users/List.vue'
              ),
          },
          {
            path: 'create',
            name: 'AdminUsersCreate',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../views/Admin/Users/Create.vue'
              ),
          },
          {
            path: 'edit/:id',
            name: 'AdminUsersEdit',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '../views/Admin/Users/Edit.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
