<template>
  <NavigationDrawer>
    <v-list-item
      to="/satellite-site"
      v-if="store.state.permissions.includes('satellite-site')"
    >
      <v-icon icon="mdi-satellite-variant" class="mr-1" />サテライトサイト
    </v-list-item>
    <v-list-item to="/admin" v-if="store.state.permissions.includes('admin')">
      <v-icon icon="mdi-dots-square" class="mr-1" />管理
    </v-list-item>
  </NavigationDrawer>

  <v-container fluid>
    <v-row>
      <v-col><h1>ホーム</h1></v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { useRouter } from 'vue-router'
import NavigationDrawer from '@/components/parts/NavigationDrawer.vue'
import store from '@/store/app'

export default defineComponent({
  name: 'Home',
  components: {
    NavigationDrawer,
  },
  setup() {
    const router = useRouter()

    provide(store.key, store)

    store.setup(router)

    return { store }
  },
})
</script>
