<template>
  <v-navigation-drawer :class="$style.drawer" tile v-if="expand">
    <v-list class="pa-0" nav>
      <v-list-item-header v-if="title">
        <v-row no-gutters>
          <v-col cols="10" align-self="center">
            <div :class="$style.title">{{ title }}</div>
          </v-col>
          <!-- <v-col cols="2">
            <v-btn
              :class="$style.close"
              icon="mdi-close"
              size="small"
              elevation="0"
              :ripple="false"
              absolute
              right
            />
          </v-col> -->
        </v-row>
      </v-list-item-header>
      <v-divider v-if="title" />

      <v-container :class="$style.container" fluid>
        <slot />
      </v-container>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import store from '@/store';

export default defineComponent({
  name: 'NavigationDrawer',
  props: {
    title: String,
  },
  data: () => ({
    expand: true,
  }),
})
</script>

<style module lang="scss">
.title {
  padding: 12px;
  text-align: left;
}
.close {
  border-radius: 0;
  box-shadow: none;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
  }
}
.container {
  // header = 48px, list-item-header = 41px, footer = 32px
  height: calc(100vh - 48px - 41px - 32px);
  overflow: scroll;
}
</style>
