<template>
  <v-footer :class="$style.footer">
    <!-- <v-col>SUB MENU</v-col> -->
    <v-spacer />
    <v-col cols="12" sm="6" class="text-right pa-0"><Copyright /></v-col>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Copyright from '@/components/parts/Copyright.vue'

export default defineComponent({
  name: 'Footer',
  components: { Copyright },
})
</script>

<style module lang="scss">
.footer {
  width: 100%;
  height: 16px;
  bottom: 0;
  justify-content: center;
  z-index: 3;
  font-size: 0.75rem;
  color: rgb(var(--v-theme-secondary)) !important;
  background: rgb(var(--v-theme-on-secondary)) !important;
}
</style>
