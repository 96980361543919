
import { defineComponent } from 'vue'
// import store from '@/store';

export default defineComponent({
  name: 'NavigationDrawer',
  props: {
    title: String,
  },
  data: () => ({
    expand: true,
  }),
})
