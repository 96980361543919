import { createApp } from 'vue'
import Amplify from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'

const app = createApp(App)



Amplify.configure({
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
})

// Use Vuetify
app.use(vuetify)

app.use(router).mount('#app')
