type WorkGroup = { name: string; description: string }
type WorkGroups = { [key: string]: WorkGroup }
export const Groups: WorkGroups = {
  Administrator: {
    name: '管理者',
    description: 'すべての操作が可能な権限です',
  },
  SatelliteSite: {
    name: 'サテライトサイト',
    description: 'サテライトサイトの運用ツールにアクセス可能な権限です',
  },
}

export const UserStatus = {
  // User has been created but not confirmed
  UNCONFIRMED: '未確認',
  // User has been confirmed
  CONFIRMED: '利用可能',
  // User is no longer active.
  ARCHIVED: 'アーカイブ',
  // User is disabled due to a potential security threat.
  COMPROMISED: 'セキュリティ上の問題により利用不可',
  // User status is not known.
  UNKNOWN: '不明',
  // User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.
  RESET_REQUIRED: 'パスワードリセットを要求中',
  // The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.
  FORCE_CHANGE_PASSWORD: '仮パスワード設定中',
}

export const CreateUserErrorType = {
  UserNotFoundException: 'ユーザーは存在しません。',
  UsernameExistsException: '指定されたユーザー名はすでに存在します',
  InvalidParameterException:
    'パスワードには半角大文字小文字を含む英数を8文字以上で入力してください',
  InvalidPasswordException:
    'パスワードには半角大文字小文字を含む英数を8文字以上で入力してください',
}
