<template>
  <v-app>
    <Header :signOut="store.state.signOut" />
    <v-main :class="$style.main">
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { useRouter } from 'vue-router'

import Header from '@/components/globals/Header.vue'
import Footer from '@/components/globals/Footer.vue'

import store from '@/store/app'

export default defineComponent({
  name: 'App',
  components: { Header, Footer },
  setup() {
    const router = useRouter()
    provide(store.key, store)

    store.setup(router)

    return { store }
  },
})
</script>

<style module lang="scss">
.main {
  overflow: scroll;
  height: calc(100vh - 48px);
  background-color: rgb(var(--v-theme-secondary));
}
</style>
<style lang="scss">
.v-application__wrap {
  overflow-y: hidden;
}
</style>
