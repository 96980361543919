import { GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
export * from './consts'

export const permissions = () =>
  API.graphql(
    graphqlOperation(
      gql`
        query {
          permissions
        }
      `,
    ),
  ) as GraphQLQueryResult<'permissions'>
