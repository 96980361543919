
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Copyright',
  setup() {
    const isDev = process.env.NODE_ENV === 'development'
    return { isDev }
  },
})
