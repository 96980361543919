import { render } from "./App.vue?vue&type=template&id=764951c0"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./App.vue?vue&type=style&index=0&id=764951c0&module=true&lang=scss"
cssModules["$style"] = style0
import "./App.vue?vue&type=style&index=1&id=764951c0&lang=scss"
script.render = render

export default script