<template>
  <span :class="$style.copyright">
    © Grannet Co., Ltd. All rights reserved.
    <v-icon v-if="isDev" icon="mdi-dev-to" color="success" size="20" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Copyright',
  setup() {
    const isDev = process.env.NODE_ENV === 'development'
    return { isDev }
  },
})
</script>

<style module lang="scss">
.copyright {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
</style>
