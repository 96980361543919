import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.sass'
import '@/assets/main.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

const grannet = {
  dark: false,
  colors: {
    'background': '#FFFFFF',
    'surface': '#FFFFFF',
    // primary: '#2196F3',
    // secondary: '#CFD8DC',
    // accent: '#000000',
    // error: '#FF1744',
    'primary': '#2196F3',
    'primary-darken-1': '#3700B3',
    'secondary': '#F3F4F4',
    'secondary-darken-1': '#778',
    'error': '#B00020',
    'info': '#2196F3',
    'success': '#4CAF50',
    'warning': '#FB8C00',
  },
  variables: {
    'theme-on-background': '#333333',
    'theme-on-surface': '#333333',
    'theme-on-secondary': '#333333',
    'border-color': '#000000',
    'border-opacity': 0.12,
    'border-light-color': '#FFFFFF',
    'border-light-opacity': 0.9,
    'border-dark-opacity': 0.3,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'activated-opacity': 0.12,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'dragged-opacity': 0.08,
    'pressed-opacity': 0.16,
    'kbd-background-color': '#212529',
    'kbd-color': '#FFFFFF',
  },
}

export default createVuetify({
  components,
  directives,
  locale: { defaultLocale: 'ja' },
  theme: {
    defaultTheme: 'grannet',
    themes: {
      grannet,
    },
  },
})
