import {
  InjectionKey,
  reactive,
  readonly,
  onBeforeMount,
  onUnmounted,
} from 'vue'
import { Router } from 'vue-router'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { BaseStore } from '@/store'
import { permissions } from '@/graphql/admin/permissions'

interface State {
  loginUser?: CognitoUser
  permissions: string[]
  signOut: boolean
}

const state = reactive<State>({
  permissions: [],
  signOut: false,
})

const setSignOut = (enabled: boolean) => {
  state.signOut = enabled
}

const setPermissions = (permissions: string[]) => {
  state.permissions = permissions
}

const setup = (router: Router) => {
  // セッションチェック中のフラグ
  let _checkSession = false
  const checkSession = async () => {
    await router.isReady()
    if (router.currentRoute.value.name === 'Login') {
      setSignOut(false)
      return
    }

    if (_checkSession) {
      return
    }

    _checkSession = true
    Auth.currentAuthenticatedUser()
      .then((user) => {
        state.loginUser = user

        if (state.permissions.length == 0) {
          permissions()
            .then((ret) => {
              setPermissions(ret.data?.permissions ?? [])
              console.log({ permissions: state.permissions })
              state.signOut = true
              _checkSession = false
            })
            .catch((err) => {
              console.log({ permissions: err })
              state.signOut = true
              _checkSession = false
            })
        } else {
          state.signOut = true
          _checkSession = false
        }
      })
      .catch(async () => {
        state.loginUser = undefined
        state.signOut = false

        alert('セッションが切れました。ログインしなおしてください。')
        await router.replace({ name: 'Login' })
        _checkSession = false
      })
  }

  // 画面表示時、画面遷移時にセッションを確認する
  onBeforeMount(checkSession)
  router.afterEach(checkSession)
  onBeforeMount(() => window.addEventListener('focus', checkSession))
  onUnmounted(() => window.removeEventListener('focus', checkSession))
}

interface Store {
  state: State
  setup: (router: Router) => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
}

export default store
