<template>
  <v-app-bar flat border density="compact">
    <v-app-bar-nav-icon
      @click="goHome"
      class="app-logo"
      icon="grannet"
    ></v-app-bar-nav-icon>
    <v-app-bar-title class="app-title">Grannet</v-app-bar-title>
    <v-spacer />
    <v-btn
      v-if="signOut"
      @click="onSignOut"
      color="secondary-darken-1"
      variant="outlined"
      border
    >
      ログアウト
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { Auth } from 'aws-amplify'

export default defineComponent({
  name: 'Header',
  props: {
    signOut: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()

    const onSignOut = async () => {
      await Auth.signOut()
      router.replace({ name: 'Login' })
    }

    const goHome = () => {
      router.replace({ name: 'Home' })
    }

    return {
      goHome,
      onSignOut,
    }
  },
})
</script>

<style lang="scss">
.app-logo .grannet {
  background-image: url('~@/assets/grannet-logo.png');
  background-position: center;
  background-size: contain;
}
</style>
<style scoped lang="scss">
.app-title {
  color: #9a9b9c;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding-left: 5px;
}
</style>
