
import { defineComponent, provide } from 'vue'
import { useRouter } from 'vue-router'
import NavigationDrawer from '@/components/parts/NavigationDrawer.vue'
import store from '@/store/app'

export default defineComponent({
  name: 'Home',
  components: {
    NavigationDrawer,
  },
  setup() {
    const router = useRouter()

    provide(store.key, store)

    store.setup(router)

    return { store }
  },
})
